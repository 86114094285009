<template>
  <div>
    <div class="table-responsive">
      <table
        class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
      >
        <thead>
          <tr class="text-left">
            <th style="min-width: 100px">No</th>
            <th style="min-width: 120px">Marker</th>
            <th style="min-width: 120px">Tổng số ticket</th>
            <th style="min-width: 120px">Ticket chấm đúng hạn</th>
            <th style="min-width: 120px">Ticket chấm muộn</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, i) in data_marking">
            <tr v-bind:key="i">
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >#{{ i + 1 }}</span
                >
              </td>
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >{{ item.name }}</span
                >
              </td>
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >{{
                    item.tickets.punctual.length + item.tickets.late.length
                  }}</span
                >
              </td>
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg cursor-pointer"
                  :class="{
                    'text-decoration-underline':
                      item.tickets.punctual.length > 0,
                    'light-blue--text': item.tickets.punctual.length > 0,
                  }"
                  @click="openDialogListTicket(item.tickets.punctual)"
                >
                  {{ item.tickets.punctual.length }}
                </span>
              </td>
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg cursor-pointer"
                  :class="{
                    'text-decoration-underline': item.tickets.late.length > 0,
                    'light-blue--text': item.tickets.late.length > 0,
                  }"
                  @click="openDialogListTicket(item.tickets.late)"
                >
                  {{ item.tickets.late.length }}
                </span>
              </td>
            </tr>
          </template>
          <template v-if="!data_marking.length">
            <tr>
              <td style="text-align: center" colspan="9">No data</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <div>
      <v-row justify="center">
        <v-dialog
          v-model="dialogListTicket"
          max-width="10000px"
          scrollable
          persistent
        >
          <v-card>
            <v-card-title>
              <v-col cols="11">
                <span class="headline">Danh sách ticket</span>
              </v-col>
              <v-col cols="1">
                <v-icon @click="dialogListTicket = false" right
                  >mdi-close</v-icon
                >
              </v-col>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" class="d-flex">
                    <label class="text-body-1 font-weight-medium mb-6"
                      >Hiển thị tickets:</label
                    >
                    <v-radio-group
                      class="mt-0 pt-0"
                      v-model="view_display_student"
                      row
                    >
                      <v-radio
                        label="Cả hai"
                        :value="display_student.all"
                      ></v-radio>
                      <v-radio
                        label="học sinh ipp"
                        :value="display_student.ipp"
                      ></v-radio>
                      <v-radio
                        label="học sinh prep"
                        :value="display_student.prep"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" class="mb-0 pb-0">
                    <label class="text-h6 mb-0"
                      ><b>Tổng</b>: {{ list_ticket_filter.length }}</label
                    >
                  </v-col>
                  <v-col cols="12">
                    <div class="table-responsive">
                      <table
                        class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
                      >
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Khóa học</th>
                            <th scope="col">Học sinh</th>
                            <th scope="col">Lớp</th>
                            <th scope="col">Marker</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(ticket, i) in list_ticket_filter">
                            <tr :key="i">
                              <td scope="row">
                                <span
                                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                                  >#{{ ticket.id }}</span
                                >
                              </td>
                              <td>
                                <p
                                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                                >
                                  {{ displayFieldCourse(ticket.course) }}
                                </p>
                              </td>
                              <td>
                                <p
                                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                                >
                                  {{ ticket.student.name }}
                                </p>
                              </td>
                              <td>
                                <p
                                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                                  v-if="ticket.prep_class"
                                >
                                  {{ ticket.prep_class.name }}
                                </p>
                              </td>
                              <td>
                                <p
                                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                                  v-if="ticket.marker"
                                >
                                  {{ ticket.marker.name }}
                                </p>
                              </td>
                              <td>
                                <div style="display: flex">
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                      <router-link
                                        :to="{
                                          name: 'MarkingTicketAnswer',
                                          params: { id: ticket.id },
                                        }"
                                        target="_blank"
                                      >
                                        <button
                                          class="btn btn-icon btn-light-primary btn-sm"
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <span
                                            class="svg-icon svg-icon-md svg-icon-primary"
                                          >
                                            <v-icon color=""
                                              >mdi-eye-settings</v-icon
                                            >
                                          </span>
                                        </button>
                                      </router-link>
                                    </template>
                                    <span>Marking</span>
                                  </v-tooltip>
                                </div>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <button
                class="btn text-uppercase mr-2"
                style="color: #f64e60"
                @click="dialogListTicket = false"
              >
                Close
              </button>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "MarkingStatus",
  props: {
    data_marking: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      display_student: {
        all: 1,
        ipp: 2,
        prep: 3,
      },
      list_ticket: [],
      list_ticket_filter: [],
      dialogListTicket: false,
      view_display_student: 1,
    };
  },
  watch: {
    view_display_student(val) {
      this.resetDataTicketByFilter(val);
    },
  },
  methods: {
    openDialogListTicket(tickets) {
      this.list_ticket = JSON.parse(JSON.stringify(tickets));
      this.list_ticket_filter = JSON.parse(JSON.stringify(tickets));
      this.dialogListTicket = true;
    },
    displayFieldCourse(json) {
      if (this.$utils.IsJsonString(json.name)) {
        let course_name = JSON.parse(json.name);
        return course_name.vi;
      }
      return "";
    },
    resetDataTicketByFilter(value) {
      let data = JSON.parse(JSON.stringify(this.list_ticket));
      if (value === this.display_student.all) {
        this.list_ticket_filter = data;
      } else if (value === this.display_student.ipp) {
        this.list_ticket_filter = data.filter((e) => {
          return e.prep_class != null;
        });
      } else {
        this.list_ticket_filter = data.filter((e) => {
          return e.prep_class == null;
        });
      }
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.table td {
  vertical-align: middle;
}
</style>
